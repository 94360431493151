<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card>
            <v-card-text class="pa-5">
              <v-card
                class="d-flex justify-space-between mb-6 no-box-shadow"
                :elevation="0"
              >
                <div>
                  <h3 class="title text--darken-2 font-weight-regular">
                    {{ $t("card.master-lembaga") }}
                  </h3>
                  <h6 class="subtitle-2 font-weight-light mb-2">
                    {{
                      $t(
                        "label.set up a list of institutions sending incoming mail"
                      )
                    }}
                  </h6>
                </div>
                <div>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        dark
                        small
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        @click="getInstitutionList(searchQuery)"
                      >
                        <v-icon dark> {{ iconReload }} </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("tooltip.reload data") }}</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-1"
                        fab
                        dark
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addData"
                      >
                        <v-icon dark> {{ iconPlus }} </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("tooltip.add data") }}</span>
                  </v-tooltip>
                </div>
              </v-card>
              <v-row class="d-flex justify-end">
                <v-col cols="12" md="4">
                  <v-text-field
                    clearable
                    v-model="searchQuery"
                    @keyup="textSearch($event)"
                    v-bind:label="$t('label.search')"
                    class="px-2"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="axiosProcessing">
                <v-col cols="12">
                  <v-skeleton-loader
                    type="table-heading, table-thead, table-tbody"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="pa-4">
                  <v-simple-table fixed-header height="520px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="width: 30px">No.</th>
                          <th class="text-left">
                            {{ $t("label.institution name") }}
                          </th>
                          <th class="text-left">{{ $t("label.location") }}</th>
                          <th class="text-left">
                            {{ $t("label.institution level") }}
                          </th>
                          <th class="text-left">
                            {{ $t("label.status") }}
                          </th>
                          <th class="text-center">{{ $t("label.action") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in dataList" :key="idx">
                          <td class="text-right">{{ idx + 1 }}.</td>
                          <td>{{ item.nama_lembaga }}</td>
                          <td>{{ item.lokasi }}</td>
                          <td>{{ item.level }}</td>
                          <td>{{ rowStatus(item.status) }}</td>
                          <td class="text-center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="mx-1"
                                  fab
                                  dark
                                  x-small
                                  color="orange"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="editData(item.id_hash)"
                                >
                                  <v-icon dark> {{ iconPencil }} </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("tooltip.edit") }}</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="mx-1"
                                  fab
                                  dark
                                  x-small
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="confirmDelete(item.id_hash)"
                                >
                                  <v-icon dark> {{ iconDelete }} </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("tooltip.disable") }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>
    <input-modal
      :showDialog="showDialog"
      :dataId="dataId"
      @closeModal="closeModal"
    ></input-modal>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import InputModal from "./inputmodal.vue";

import { mdiPencil, mdiCancel, mdiPlus, mdiReload, mdiDelete } from "@mdi/js";
export default {
  name: "daftar-lembaga",
  components: {
    InputModal,
  },
  data: () => ({
    iconPencil: mdiPencil,
    iconLock: mdiCancel,
    iconPlus: mdiPlus,
    iconReload: mdiReload,
    iconDelete: mdiDelete,
    showDialog: false,
    dataId: null,
    searchQuery: "",
    timer: null,
  }),
  created() {
    this.initPage();
  },
  computed: {
    ...mapState("institution", [
      "institutionList",
      "singleInstitution",
      "axiosProcessing",
    ]),
    ...mapState("user", ["currentUser"]),
    dataList() {
      if (!this.institutionList) return [];
      return this.institutionList;
    },
  },
  watch: {
    searchQuery(val) {
      
      if (val) return;

      setTimeout(() => {
        //do search
        if (this.axiosProcessing === true) return;
        this.getInstitutionList(this.searchQuery);
      }, 300);
    },
  },
  methods: {
    ...mapActions("institution", ["getInstitutionList", "deleteInstitution"]),
    rowStatus(param) {
      return parseInt(param) === 1 ? "Aktif" : "Non Aktif";
    },
    initPage() {
      if (!this.currentUser) return this.$router.push({ name: "dashboard" });
      this.getInstitutionList(this.searchQuery);
    },
    closeModal() {
      this.showDialog = false;
      this.dataId = null;
    },
    addData() {
      this.showDialog = true;
    },
    editData(idHash) {
      this.dataId = idHash;
      this.showDialog = true;
    },
    textSearch() {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        // console.log(e);
        this.getInstitutionList(this.searchQuery);
      }, 1000);
    },
    confirmDelete(idHash) {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are you sure to delete this institution"),
          title: this.$t("msgbox.delete institution"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;
          this.deleteInstitution(idHash);
        });
    },
  },
};
</script>