<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card class="yeyeye">
      <v-card-title>
        <span class="text-h5" v-if="dataId">{{
          $t("card.edit institution")
        }}</span>
        <span class="text-h5" v-else>{{ $t("card.add institution") }}</span>
      </v-card-title>
      <v-card-text v-if="dataLoading">
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.namalembaga"
                :label="'*' + $t('label.institution name')"
                required
                maxlength="200"
              ></v-text-field>
              <v-alert
                v-if="
                  $v.form.namalembaga.$error && !$v.form.namalembaga.required
                "
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.institution name must be filled") }}
              </v-alert>
              <v-alert
                v-if="
                  $v.form.namalembaga.$error && !$v.form.namalembaga.minLength
                "
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.institution name min 10 chars") }}
              </v-alert>
              <v-alert
                v-if="
                  $v.form.namalembaga.$error && !$v.form.namalembaga.maxLength
                "
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.institution name max 200 chars") }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.deskripsi"
                maxlength="200"
                :label="$t('label.description')"
                required
              ></v-text-field>
              <v-alert
                v-if="$v.form.deskripsi.$error && !$v.form.deskripsi.maxlength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.description max length is 200 chars") }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.lokasi"
                maxlength="150"
                :label="'*' + $t('label.location')"
                required
              ></v-text-field>
              <v-alert
                v-if="$v.form.lokasi.$error && !$v.form.lokasi.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.institution location must be filled") }}
              </v-alert>
              <v-alert
                v-if="$v.form.lokasi.$error && !$v.form.lokasi.minLength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.location min 5 chars") }}
              </v-alert>
              <v-alert
                v-if="$v.form.lokasi.$error && !$v.form.lokasi.maxLength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.location max 150 chars") }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="form.level"
                :items="institutionLevel"
                label="*Level"
              ></v-select>
              <v-alert
                v-if="$v.form.level.$error && !$v.form.level.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-1
                  mb-0
                "
              >
                {{ $t("error.pick institution level") }}
              </v-alert>
            </v-col>
            <small>*{{ $t("text.must be filled") }}</small>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="d-flex justify-end mr-2 pt-0" v-if="edited">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <label
              v-on="on"
              v-bind="attrs"
              class="avoore-checkbox path d-block"
              v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
            >
              <input
                type="checkbox"
                class="d-inline-flex"
                v-model="form.chkaktif"
                value="1"
                id="chk-aktif"
              />
              <svg viewBox="0 0 21 21">
                <path
                  d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                ></path>
              </svg>
              <span for="chk-agree" class="checkbox-title green--text font-weight-medium">{{
                $t("label.institution status active")
              }}</span>
            </label>
          </template>
          <span>{{ $t("tooltip.if unchecked the institution wont show in create draft external memo") }}</span>
        </v-tooltip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal">
          {{ $t("button.cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="saveLembaga"
          :disabled="savingProcess"
        >
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const { required, minLength, maxLength } = require("vuelidate/lib/validators");

import { validationMixin } from "vuelidate";
import { mdiAlertCircleOutline } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import { http } from "@/http/http.js";

export default {
  name: "input-modal",
  props: {
    showDialog: Boolean,
    dataId: null,
  },
  data: () => ({
    dataLoading: false,
    edited: false,
    institutionLevel: [
      "Desa/Kelurahan",
      "Kecamatan",
      "Kabupaten/Kota",
      "Provinsi",
      "Nasional",
      "Internasional",
    ],
    iconAlert: mdiAlertCircleOutline,
    form: {
      namalembaga: null,
      deskripsi: null,
      lokasi: null,
      level: null,
      chkaktif: 1,
    },
  }),
  mixins: [validationMixin],
  validations: {
    form: {
      namalembaga: {
        required,
        maxLength: maxLength(200),
        minLength: minLength(10),
      },
      deskripsi: {
        required: false,
        maxLength: maxLength(200),
      },
      lokasi: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(150),
      },
      level: { required },
    },
  },
  watch: {
    dataId(val) {
      if (!val) return;
      this.getSingleData(val);
    },
  },
  computed: {
    ...mapState("institution", [
      "savingProcess",
      "savingSuccess",
      "axiosProcessing",
      "singleInstitution",
    ]),
  },
  methods: {
    ...mapActions("institution", [
      "saveInstitution",
      "updateInstitution",
      "setSavingSuccess",
      "getInstitutionDetail",
      "setAxiosProcess",
    ]),
    closeModal() {
      this.$v.$reset();
      this.form = {
        namalembaga: null,
        deskripsi: null,
        lokasi: null,
        level: null,
        chkaktif: 1,
      };
      this.setSavingSuccess(false);
      this.$emit("closeModal");
    },
    async saveLembaga() {
      //  this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (this.dataId) return this.updateLembaga();

      await this.saveInstitution(this.form);
      if (this.savingProcess === false && this.savingSuccess === true)
        this.closeModal();
    },
    async updateLembaga() {
      await this.updateInstitution({ id: this.dataId, data: this.form });
      if (this.savingProcess === false && this.savingSuccess === true)
        this.closeModal();
    },

    async getSingleData(val) {
      if (!val) return;
      if (this.dataLoading === true) return;
      this.dataLoading = true;

      return new Promise((resolve) => {
        http.get(`/admin/lembagas/${val}`).then((response) => {
          if (
            response &&
            (response.status == 200 || response.status == "success")
          ) {
            this.form = {
              namalembaga: response.data.nama_lembaga,
              deskripsi: response.data.deskripsi,
              lokasi: response.data.lokasi,
              level: response.data.level,
              chkaktif: parseInt(response.data.row_status),
            };
            this.dataLoading = false;
            this.edited = true;
          } else {
            this.dataLoading = false;
            this.edited = false;
          }
          return resolve(response.data);
        });
      });
    },
  },
};
</script>